import './App.css';
import './css/light-hc.css';
/* import './mio.css'; */ 
import React from 'react';
import Navbar from './Navbar';
import Content from './Content';
import './fonts.css';


function App() {
  return (
    <div className="App app-container">
      <div className="column-container">
        
        <div className="main-content">
          <header className="App-header">
            <Navbar />
      
          </header>
          <main className="content">
            <Content />
            
            <br />
       
            <br />
          </main>
        </div>
      </div>
    </div>
  );
}




export default App;
