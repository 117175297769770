import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardContent, Typography, CircularProgress } from '@mui/material';
import UserCard from './UserCard';
import { fetchUserName } from './api';
import './Card.css';

const TopBattleUsers = ({ clanName, battleName, battle }) => {
  const [topBattleUsers, setTopBattleUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTopMembers = (battle) => {
    if (!battle || !battle.ProcessedAwards || !battle.PointContributions) {
      return [];
    }
    return battle.PointContributions
      .sort((a, b) => b.Points - a.Points)
      .slice(0, 5);
  };

  useEffect(() => {
    const fetchUserNames = async () => {
      setLoading(true); // Start loading
      const topMembers = getTopMembers(battle);
      const userNamesMap = {};
      for (const member of topMembers) {
        const userName = await fetchUserName(member.UserID);
        userNamesMap[member.UserID] = userName;
      }
      const usersWithNames = topMembers.map((member) => ({
        userId: member.UserID,
        userName: userNamesMap[member.UserID],
        points: member.Points,
      }));
      setTopBattleUsers(usersWithNames);
      setLoading(false); // Stop loading
    };

    fetchUserNames();
  }, [battle]);

  return (
    <div className="top-users-card expanded-card">
      <CardContent className="card-content">
        <Typography variant="h5" component="div" className="card-title">
          {battleName}
        </Typography>
        <div className="top-battle-users-container">
          {loading ? (
            <CircularProgress /> // Display loading indicator
          ) : (
            topBattleUsers.map(({ userId, userName, points }) => (
              <div className="card-top5" key={userId}>
                <UserCard userName={userName} points={points} />
              </div>
            ))
          )}
        </div>
      </CardContent>
    </div>
  );
};

TopBattleUsers.propTypes = {
  clanName: PropTypes.string.isRequired,
  battleName: PropTypes.string.isRequired,
  battle: PropTypes.shape({
    ProcessedAwards: PropTypes.bool.isRequired,
    PointContributions: PropTypes.arrayOf(
      PropTypes.shape({
        UserID: PropTypes.string.isRequired,
        Points: PropTypes.number.isRequired,
      })
    ).isRequired,
  }).isRequired,
};

export default TopBattleUsers;
