import axios from 'axios';

// Set base URLs for different environments
const LOCAL_API_BASE_URL = 'http://localhost:5000/api/user';
const PROD_API_BASE_URL = 'https://99values.info/api/user';
const BIGGAMES_API_BASE_URL = 'https://biggamesapi.io/api/clan';

const CORS_PROXY = 'https://corsproxy.io/?';

// Fetch user name from Roblox API
export const fetchUserName = async (userId) => {
  try {
    const url = process.env.NODE_ENV === 'production'
      ? `${CORS_PROXY}https://users.roblox.com/v1/users/${userId}`
      : `${LOCAL_API_BASE_URL}/${userId}`;
    const response = await axios.get(url);
    return response.data.name;
  } catch (error) {
    console.error(`Error fetching user name for ID ${userId}:`, error);
    return userId; // Fallback to userId if there's an error
  }
};

// Fetch clan data from Biggames API
export const fetchClanData = async (clanIdOrName) => {
  try {
    const url = `${CORS_PROXY}${BIGGAMES_API_BASE_URL}/${clanIdOrName}`;
    const response = await axios.get(url);
    return response.data;
  } catch (error) {
    console.error('Error fetching clan information:', error);
    throw error;
  }
};
