import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { CardContent, Typography } from '@mui/material';
import UserCard from './UserCard';
import './Card.css';
import { fetchUserName } from './api';  // Import fetchUserName function

const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const batchFetchUserNames = async (userIds) => {
  const batchSize = 5;
  const userNamesMap = {};

  for (let i = 0; i < userIds.length; i += batchSize) {
    const batch = userIds.slice(i, i + batchSize);

    await Promise.all(batch.map(async (userId) => {
      const userName = await fetchUserName(userId);
      userNamesMap[userId] = userName;
    }));

    // Delay between batches to handle rate limiting
    await delay(1000); // Adjust delay as necessary
  }

  return userNamesMap;
};

const getTopMembers = (battle) => {
  if (!battle || !battle.ProcessedAwards || !battle.PointContributions) {
    return [];
  }
  const sortedContributions = battle.PointContributions
    .sort((a, b) => b.Points - a.Points)
    .slice(0, 5);
  return sortedContributions.map(contribution => contribution.UserID);
};

const TopUsers = ({ clan }) => {
  const [topUsers, setTopUsers] = useState([]);

  const calculateUserScores = async () => {
    const userScores = {};
    const userIds = [];

    if (clan && clan.Battles) {
      for (const battleKey in clan.Battles) {
        if (clan.Battles.hasOwnProperty(battleKey)) {
          const battle = clan.Battles[battleKey];
          if (battle.ProcessedAwards) {
            const topMembers = getTopMembers(battle);
            topMembers.forEach((userId, index) => {
              const points = 5 - index;
              if (userScores[userId]) {
                userScores[userId] += points;
              } else {
                userScores[userId] = points;
                userIds.push(userId);
              }
            });
          }
        }
      }
    }

    const userNamesMap = await batchFetchUserNames(userIds);

    const sortedUsers = Object.entries(userScores)
      .sort(([, a], [, b]) => b - a)
      .slice(0, 10) // Get top 10 users
      .map(([userId, points]) => ({
        userId,
        userName: userNamesMap[userId],
        points,
      }));

    setTopUsers(sortedUsers);
  };

  useEffect(() => {
    if (clan) {
      calculateUserScores();
    }
  }, [clan]);

  return (
    <div className="top-users-card expanded-card">
      <CardContent className="card-content">
        <Typography variant="h5" component="div" className="card-title">
          Top 10 Users - All Battles
        </Typography>
        <div className="top-users-container">
          {topUsers.map(({ userId, userName, points }) => (
            <UserCard key={userId} userName={userName} points={points} />
          ))}
        </div>
      </CardContent>
    </div>
  );
};

TopUsers.propTypes = {
  clan: PropTypes.object.isRequired,
};

export default TopUsers;
