import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import './Card.css';

const UserCard = ({ userName, points }) => {
  return (
    <div className="card-price">
      <Typography variant="body1">
        User Name: <strong>{userName}</strong>
      </Typography>
      <Typography variant="body1">
        Total Points: <strong>{points}</strong>
      </Typography>
    </div>
  );
};

UserCard.propTypes = {
  userName: PropTypes.string.isRequired,
  points: PropTypes.number.isRequired,
};

export default UserCard;
