import React from 'react';
import './Navbar.css';

function Navbar() {
  return (
    <header>
      <h3 className='app-title'>Pet Simulator 99 <br></br></h3>
      {/* Add menu items, buttons, or links as needed */}
    </header>
  );
}

export default Navbar;