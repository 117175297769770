import { useState, useEffect } from 'react';
import useFetchClanData from './useFetchClanData';
import SearchBar from './SearchBar';
import TopUsers from './TopUsers';
import TopBattleUsers from './TopBattleUsers';
import './Content.css';

const Content = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { clan, error, isLoading } = useFetchClanData(searchTerm);

  const handleSearch = () => {
    if (searchTerm.trim() === '') {
      alert('Please enter a valid Clan ID or Name');
    }
  };

  return (
    <main className="content">
      <h2>Search for Clans</h2>
      <SearchBar
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
      />
      {isLoading && <div className="loading-message">Loading...</div>}
      {error && <div className="error-message">{error}</div>}
      {clan && (
        <>
          <h2>
            Clan: {clan.Name}
            {clan.Icon && <img src={`https://corsproxy.io/?${clan.Icon}`} alt={`${clan.Name} logo`} style={{ marginLeft: '10px', width: '50px', height: '50px' }} />}
          </h2>
          <p>{clan.Desc}</p>
          <TopUsers clan={clan} />
          <div className="cards">
            {clan.Battles && Object.entries(clan.Battles).map(([battleName, battle]) => (
              battle.ProcessedAwards && (
                <TopBattleUsers
                  key={battleName}
                  clanName={clan.Name}
                  battleName={battleName}
                  battle={battle}
                />
              )
            ))}
          </div>
        </>
      )}
    </main>
  );
};

export default Content;
