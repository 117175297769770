import { useState, useEffect } from 'react';
import { fetchClanData } from './api';

const useFetchClanData = (searchTerm) => {
  const [clan, setClan] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!searchTerm) return;

    const getClanData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        const data = await fetchClanData(searchTerm);
        if (data && data.status === "ok" && data.data) {
          setClan(data.data);
        } else {
          setError('No clan data found');
          setClan(null);
        }
      } catch (error) {
        setError('Error fetching clan information');
        setClan(null);
      } finally {
        setIsLoading(false);
      }
    };

    getClanData();
  }, [searchTerm]);

  return { clan, error, isLoading };
};

export default useFetchClanData;
