import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search'; 

const SearchBar = ({ searchTerm, setSearchTerm, handleSearch }) => {
  return (
    <TextField
      variant="outlined"
      fullWidth
      placeholder="Search"
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
        style: { borderRadius: 50, backgroundColor: '#f7f7f7' },
      }}
      style={{ margin: '20px 0' }}
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          handleSearch();
        }
      }}
    />
  );
};

export default SearchBar;
